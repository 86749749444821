.social-wrapper {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 0 1em;
  order: 3;


  .social {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    line-height: 0;

    .social-icon {
      @include invisible-text();
      display: inline-flex;
      gap: 0;
      &::before {
        mask-repeat: no-repeat;
        mask-size: 50%;
        mask-position: 50% 50%;
        background-color: $white;
        content: "";
        display: block;
        height: 40px;
        width: 40px;
      }
      &.spotify {
        &::before {
          mask-image: $spotify;
        }
      }
      &.youtube {
        &::before {
          mask-image: $youtube;
        }
      }
      &.instagram {
        &::before {
          mask-image: $instagram;
        }
      }
      &.facebook {
        &::before {
          mask-image: $facebook;
        }
      }
      &.twitter {
        &::before {
          mask-image: $twitter;
        }
      }
      &:hover {
        &::before {
          background-color: $orange;
          transition: ease-in-out .2s;
        }
      }
    }
  }

  @media only screen and (max-width: 1080px) {
    margin: 0 auto;
    position: static;
    top: 0;
    right: 0;
    .social {
      padding: 0;
      flex-direction: column;
      .social-icon {
        @include invisible-text();
        gap: 0;

        &::before {
          mask-repeat: no-repeat;
          mask-size: 50%;
          mask-position: 50% 50%;
          height: 80px;
          width: 80px;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .social {
      .social-icon {
        &::before {
          height: 60px;
          width: 60px;
        }
      }
    }
  }
}




