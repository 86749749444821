header {
  display: flex;
  padding: 1em;
  justify-content: space-between;

  .logo {
    order: 2;
    padding-top: .8em;
    img {
      min-height: 100px;
      min-width: 250px;
    }
  }

  @media only screen and (max-width: 1080px) {
    flex-direction: row;
    align-items: baseline;

    .logo {
      margin: 0 auto;
      padding-top: 0;
      width: 70%;
    }
  }
}
