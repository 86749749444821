.cookie-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 1.6rem;
  text-align: center;
  background-color: $black-09;
  transition: 1s ease;
  -webkit-transition: 1s ease;
  -moz-transition: 1s ease;

  &.read {
    animation-name: hideCookies;
    animation-duration: 2s;
    animation-fill-mode: forwards;
  }
}

@keyframes hideCookies {
  0% {bottom: 0;}
  99% {bottom: -10em;}
  100% {
    bottom: -10em;
    display: none;
  }
}
