.band {
  img {
    width: 60vw;
  }
  @media only screen and (max-width: 1080px) {
    img {
      width: 100%;
    }
  }
}
