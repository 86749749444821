.main-content{
  &.frontpage {
    align-items: center;
    flex: auto;
    flex-direction: column;
    justify-content: flex-end;

    .section {
      background-color: transparent;
    }
  }
}
