@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap");

// Colors
$black: #000;
$darker-grey: #222;
$dark-grey: #444;
$light-grey: #aaa;
$white: #fff;
$orange: #da9c69;

$black-01: rgba(0, 0, 0, .1);
$black-02: rgba(0, 0, 0, .2);
$black-03: rgba(0, 0, 0, .3);
$black-04: rgba(0, 0, 0, .4);
$black-05: rgba(0, 0, 0, .5);
$black-06: rgba(0, 0, 0, .6);
$black-07: rgba(0, 0, 0, .7);
$black-08: rgba(0, 0, 0, .8);
$black-09: rgba(0, 0, 0, .9);

// Pages.
$page-bg: url("/dist/images/base/bg/bg.png");

// Social icons
$spotify: url(/vendor/twbs/bootstrap-icons/icons/spotify.svg);
$youtube: url(/vendor/twbs/bootstrap-icons/icons/youtube.svg);
$instagram: url(/vendor/twbs/bootstrap-icons/icons/instagram.svg);
$facebook: url(/vendor/twbs/bootstrap-icons/icons/facebook.svg);
$twitter: url(/vendor/twbs/bootstrap-icons/icons/twitter.svg);

// Misc
@mixin text() {
  color: $white;
  font-family: "Comfortaa", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  margin: 0;

  @media only screen and (max-width: 1080px) {
    font-size: 1em;
  }
}

@mixin invisible-text() {
  text-indent: -9999px;
}


