.embed {
  position: relative;
  width: 60vw;
  padding-bottom: 33.25%;
  margin: 0;
  .video,
  .audio {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  &.double-video-wrapper {
    width: 100%;
    margin: 0;
    padding-bottom: 28.25%;
    position: relative;

    .video {
      width: 50%;
      padding: 0 1em;

      &:last-child {
        right: 0;
        left: auto;
      }
    }
  }

  @media only screen and (max-width: 1080px) {
    width: 100%;
    padding-bottom: 56.25%;
    &.double-video-wrapper {
      padding-bottom: 50em;

      .video {
        padding: 1em 0;
        position: absolute;
        width: 100%;
        bottom: 50%;
        top: 0;
        right: 0;
        left: 0;
        height: 50%;

        &:last-child {
          top: 50%;
          bottom: 0;
          right: 0;
          left: 0;
        }
      }
    }
  }
}

