.pswp-gallery {
  .item {
    display: inline-block;
    padding: 1em 0;
    width: 23%;

    img {
      height: 200px;
      width: 150px;
      object-fit: fill;
    }
  }
}
