.quote-wrapper {
  padding: 1em 0;

  .quote-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    padding: 1em 0;
    &:nth-child(odd) {
      margin-right: 0;
      margin-left: auto;
    }

    .author {
      margin-top: 1em;
    }
  }
  @media only screen and (max-width: 1080px) {
    .quote-item {
      width: 100%;

      &:nth-child(odd) {
        margin-right: auto;
        margin-left: auto;
      }
    }
  }
}

.quote {
  @include text();

  font-style: italic;
  font-size: 1.5em;
}
