.main-content {
  height: inherit;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .section {
    max-width: 100vw;
    padding: 3em 0;
    background-color: $black-04;

    &:nth-child(even) {
      background-color: $black-02;
    }

    > div {
      width: 90vw;
      margin: 0 auto;
    }
  }
}
