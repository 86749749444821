body {
  background-image: $page-bg;
  background-size: cover;
  background-repeat: no-repeat;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  height: fit-content;
  position: relative;

  @include text();

  @media only screen and (max-width: 1080px) {
    font-size: 1.5em;
    .main-content {
      width: 100vw;
    }
  }
}

div {
  max-width: inherit;
  width: inherit;
}

h1,
h2 {
  margin-bottom: 2em;
  &.margin {
    margin: 2em;
  }
  &.bottom-less {
    margin-bottom: 1em;
  }
}

h1 {
  &.invisible-text {
    display: none;
  }
}

p {
  @include text();
}

strong {
  font-weight: 700;
}

a:not(.logo, .social-icon, .clean) {
  @include text();
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  &:hover {
    background-position: 0;
    &::before {
      width: 100%;
    }
  }
  &:before {
    content: '';
    background: $orange;
    display: block;
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 0;
    height: 2px;
    transition: all 0.3s ease-in-out;
  }

  @media only screen and (max-width: 1080px) {
    font-size: 2em;
    background-position: 0;
    &::before {
      width: 100%;
    }
  }

  @media only screen and (max-width: 768px) {
    font-size: 1em;
  }
}

.separator {
  margin: 1em 0;
}

.invisible-text {
  @include invisible-text();
}

.centered-wrapper {
  margin: auto;
  text-align: center;
  background: radial-gradient(rgba(0, 0, 0, .4), transparent 50%);
  min-height: 20vh;
  width: 98vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1080px) {
    width: auto;
  }
}
