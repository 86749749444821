.navbar {
  padding: 0 1.5em 0 0;
  order: 1;

  .nav {
    display: none;
    transition:  opacity .5s ease-in-out, display .5s ease-in-out, transform .8s ease-in-out;
    position: absolute;
    left: 0;
    justify-content: flex-start;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-link {
    @include text;
    padding: 0;
    margin: 1em 0;
  }

  label.hamburger {
    display: block;
    width: 30px;
    height: 40px;
    position: relative;
    cursor: pointer;
  }

  input#hamburger {
    display:none
  }

  .line {
    position: absolute;
    height: 3px;
    width: 100%;
    background: $white;
    transition: 0.5s;
  }
  .line:nth-child(1) { top: 10px; }
  .line:nth-child(2) { top: 20px; }
  .line:nth-child(3) { top: 30px; }

  #hamburger:checked ~ .hamburger .line:nth-child(1){
    transform: translateY(10px) rotate(-45deg);
  }

  #hamburger:checked ~ .hamburger .line:nth-child(2){
    opacity:0;
  }

  #hamburger:checked ~ .hamburger .line:nth-child(3){
    transform: translateY(-10px) rotate(45deg);
  }

  #hamburger:checked ~ .nav {
    opacity: 1;
    display: flex;
    transform: translate(0, 7em);
  }

  @starting-style {
    #hamburger:checked ~ .nav {
      opacity: 0;
      transform: translate(0, 8em);
    }
  }

  @media only screen and (max-width: 1080px) {
    margin: 0 auto;

    padding-left: 1.3em;
    top: 0;
    left: 0;
    position: static;
    z-index: 100;

    label.hamburger {
      width: 60px;
      height: 80px;
    }

    .line:nth-child(1) { top: 20px; }
    .line:nth-child(2) { top: 40px; }
    .line:nth-child(3) { top: 60px; }

    #hamburger:checked ~ .hamburger .line:nth-child(1){
      transform: translateY(20px) rotate(-45deg);
    }

    #hamburger:checked ~ .hamburger .line:nth-child(3){
      transform: translateY(-20px) rotate(45deg);
    }

    @starting-style {
      #hamburger:checked ~ .nav {
        opacity: 0;
        transform: translate(0, 0);
      }
    }

    #hamburger:checked ~ .nav {
      transform: translate(0, 0);
    }

    .nav {
      flex-direction: column;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $black-09;
      transform: translate(0);

      .nav-item:first-child {
        margin-top: 5em;
      }

      .nav-item {
        width: 100%;
        text-align: center;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    label.hamburger {
      height: 35px;
      width: 40px;
    }

    .line:nth-child(1) { top: 0; }
    .line:nth-child(2) { top: 15px; }
    .line:nth-child(3) { top: 30px; }

    #hamburger:checked ~ .hamburger .line:nth-child(1){
      transform: translateY(15px) rotate(-45deg);
    }

    #hamburger:checked ~ .hamburger .line:nth-child(3){
      transform: translateY(-15px) rotate(45deg);
    }
  }
}
