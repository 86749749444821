@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap");
body {
  background-image: url("/dist/images/base/bg/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  height: fit-content;
  position: relative;
  color: #fff;
  font-family: "Comfortaa", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  margin: 0;
}
@media only screen and (max-width: 1080px) {
  body {
    font-size: 1em;
  }
}
@media only screen and (max-width: 1080px) {
  body {
    font-size: 1.5em;
  }
  body .main-content {
    width: 100vw;
  }
}

div {
  max-width: inherit;
  width: inherit;
}

h1,
h2 {
  margin-bottom: 2em;
}
h1.margin,
h2.margin {
  margin: 2em;
}
h1.bottom-less,
h2.bottom-less {
  margin-bottom: 1em;
}

h1.invisible-text {
  display: none;
}

p {
  color: #fff;
  font-family: "Comfortaa", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  margin: 0;
}
@media only screen and (max-width: 1080px) {
  p {
    font-size: 1em;
  }
}

strong {
  font-weight: 700;
}

a:not(.logo, .social-icon, .clean) {
  color: #fff;
  font-family: "Comfortaa", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  margin: 0;
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}
@media only screen and (max-width: 1080px) {
  a:not(.logo, .social-icon, .clean) {
    font-size: 1em;
  }
}
a:not(.logo, .social-icon, .clean):hover {
  background-position: 0;
}
a:not(.logo, .social-icon, .clean):hover::before {
  width: 100%;
}
a:not(.logo, .social-icon, .clean):before {
  content: "";
  background: #da9c69;
  display: block;
  position: absolute;
  bottom: 2px;
  left: 0;
  width: 0;
  height: 2px;
  transition: all 0.3s ease-in-out;
}
@media only screen and (max-width: 1080px) {
  a:not(.logo, .social-icon, .clean) {
    font-size: 2em;
    background-position: 0;
  }
  a:not(.logo, .social-icon, .clean)::before {
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  a:not(.logo, .social-icon, .clean) {
    font-size: 1em;
  }
}

.separator {
  margin: 1em 0;
}

.invisible-text {
  text-indent: -9999px;
}

.centered-wrapper {
  margin: auto;
  text-align: center;
  background: radial-gradient(rgba(0, 0, 0, 0.4), transparent 50%);
  min-height: 20vh;
  width: 98vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 1080px) {
  .centered-wrapper {
    width: auto;
  }
}

.cookie-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 1.6rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.9);
  transition: 1s ease;
  -webkit-transition: 1s ease;
  -moz-transition: 1s ease;
}
.cookie-bar.read {
  animation-name: hideCookies;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

@keyframes hideCookies {
  0% {
    bottom: 0;
  }
  99% {
    bottom: -10em;
  }
  100% {
    bottom: -10em;
    display: none;
  }
}
footer {
  background-color: rgba(0, 0, 0, 0.8);
  color: #aaa;
  padding: 0.2em;
  display: flex;
  justify-content: center;
}

header {
  display: flex;
  padding: 1em;
  justify-content: space-between;
}
header .logo {
  order: 2;
  padding-top: 0.8em;
}
header .logo img {
  min-height: 100px;
  min-width: 250px;
}
@media only screen and (max-width: 1080px) {
  header {
    flex-direction: row;
    align-items: baseline;
  }
  header .logo {
    margin: 0 auto;
    padding-top: 0;
    width: 70%;
  }
}

.pswp-gallery .item {
  display: inline-block;
  padding: 1em 0;
  width: 23%;
}
.pswp-gallery .item img {
  height: 200px;
  width: 150px;
  object-fit: fill;
}

.quote-wrapper {
  padding: 1em 0;
}
.quote-wrapper .quote-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding: 1em 0;
}
.quote-wrapper .quote-item:nth-child(odd) {
  margin-right: 0;
  margin-left: auto;
}
.quote-wrapper .quote-item .author {
  margin-top: 1em;
}
@media only screen and (max-width: 1080px) {
  .quote-wrapper .quote-item {
    width: 100%;
  }
  .quote-wrapper .quote-item:nth-child(odd) {
    margin-right: auto;
    margin-left: auto;
  }
}

.quote {
  color: #fff;
  font-family: "Comfortaa", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  margin: 0;
  font-style: italic;
  font-size: 1.5em;
}
@media only screen and (max-width: 1080px) {
  .quote {
    font-size: 1em;
  }
}

.embed {
  position: relative;
  width: 60vw;
  padding-bottom: 33.25%;
  margin: 0;
}
.embed .video,
.embed .audio {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed.double-video-wrapper {
  width: 100%;
  margin: 0;
  padding-bottom: 28.25%;
  position: relative;
}
.embed.double-video-wrapper .video {
  width: 50%;
  padding: 0 1em;
}
.embed.double-video-wrapper .video:last-child {
  right: 0;
  left: auto;
}
@media only screen and (max-width: 1080px) {
  .embed {
    width: 100%;
    padding-bottom: 56.25%;
  }
  .embed.double-video-wrapper {
    padding-bottom: 50em;
  }
  .embed.double-video-wrapper .video {
    padding: 1em 0;
    position: absolute;
    width: 100%;
    bottom: 50%;
    top: 0;
    right: 0;
    left: 0;
    height: 50%;
  }
  .embed.double-video-wrapper .video:last-child {
    top: 50%;
    bottom: 0;
    right: 0;
    left: 0;
  }
}

.navbar {
  padding: 0 1.5em 0 0;
  order: 1;
}
.navbar .nav {
  display: none;
  transition: opacity 0.5s ease-in-out, display 0.5s ease-in-out, transform 0.8s ease-in-out;
  position: absolute;
  left: 0;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start;
}
.navbar .nav-link {
  color: #fff;
  font-family: "Comfortaa", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  margin: 0;
  padding: 0;
  margin: 1em 0;
}
@media only screen and (max-width: 1080px) {
  .navbar .nav-link {
    font-size: 1em;
  }
}
.navbar label.hamburger {
  display: block;
  width: 30px;
  height: 40px;
  position: relative;
  cursor: pointer;
}
.navbar input#hamburger {
  display: none;
}
.navbar .line {
  position: absolute;
  height: 3px;
  width: 100%;
  background: #fff;
  transition: 0.5s;
}
.navbar .line:nth-child(1) {
  top: 10px;
}
.navbar .line:nth-child(2) {
  top: 20px;
}
.navbar .line:nth-child(3) {
  top: 30px;
}
.navbar #hamburger:checked ~ .hamburger .line:nth-child(1) {
  transform: translateY(10px) rotate(-45deg);
}
.navbar #hamburger:checked ~ .hamburger .line:nth-child(2) {
  opacity: 0;
}
.navbar #hamburger:checked ~ .hamburger .line:nth-child(3) {
  transform: translateY(-10px) rotate(45deg);
}
.navbar #hamburger:checked ~ .nav {
  opacity: 1;
  display: flex;
  transform: translate(0, 7em);
}
@starting-style {
  .navbar #hamburger:checked ~ .nav {
    opacity: 0;
    transform: translate(0, 8em);
  }
}
@media only screen and (max-width: 1080px) {
  .navbar {
    margin: 0 auto;
    padding-left: 1.3em;
    top: 0;
    left: 0;
    position: static;
    z-index: 100;
  }
  .navbar label.hamburger {
    width: 60px;
    height: 80px;
  }
  .navbar .line:nth-child(1) {
    top: 20px;
  }
  .navbar .line:nth-child(2) {
    top: 40px;
  }
  .navbar .line:nth-child(3) {
    top: 60px;
  }
  .navbar #hamburger:checked ~ .hamburger .line:nth-child(1) {
    transform: translateY(20px) rotate(-45deg);
  }
  .navbar #hamburger:checked ~ .hamburger .line:nth-child(3) {
    transform: translateY(-20px) rotate(45deg);
  }
  @starting-style {
    .navbar #hamburger:checked ~ .nav {
      opacity: 0;
      transform: translate(0, 0);
    }
  }
  .navbar #hamburger:checked ~ .nav {
    transform: translate(0, 0);
  }
  .navbar .nav {
    flex-direction: column;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9);
    transform: translate(0);
  }
  .navbar .nav .nav-item:first-child {
    margin-top: 5em;
  }
  .navbar .nav .nav-item {
    width: 100%;
    text-align: center;
  }
}
@media only screen and (max-width: 768px) {
  .navbar label.hamburger {
    height: 35px;
    width: 40px;
  }
  .navbar .line:nth-child(1) {
    top: 0;
  }
  .navbar .line:nth-child(2) {
    top: 15px;
  }
  .navbar .line:nth-child(3) {
    top: 30px;
  }
  .navbar #hamburger:checked ~ .hamburger .line:nth-child(1) {
    transform: translateY(15px) rotate(-45deg);
  }
  .navbar #hamburger:checked ~ .hamburger .line:nth-child(3) {
    transform: translateY(-15px) rotate(45deg);
  }
}

.social-wrapper {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 0 1em;
  order: 3;
}
.social-wrapper .social {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  line-height: 0;
}
.social-wrapper .social .social-icon {
  text-indent: -9999px;
  display: inline-flex;
  gap: 0;
}
.social-wrapper .social .social-icon::before {
  mask-repeat: no-repeat;
  mask-size: 50%;
  mask-position: 50% 50%;
  background-color: #fff;
  content: "";
  display: block;
  height: 40px;
  width: 40px;
}
.social-wrapper .social .social-icon.spotify::before {
  mask-image: url(/vendor/twbs/bootstrap-icons/icons/spotify.svg);
}
.social-wrapper .social .social-icon.youtube::before {
  mask-image: url(/vendor/twbs/bootstrap-icons/icons/youtube.svg);
}
.social-wrapper .social .social-icon.instagram::before {
  mask-image: url(/vendor/twbs/bootstrap-icons/icons/instagram.svg);
}
.social-wrapper .social .social-icon.facebook::before {
  mask-image: url(/vendor/twbs/bootstrap-icons/icons/facebook.svg);
}
.social-wrapper .social .social-icon.twitter::before {
  mask-image: url(/vendor/twbs/bootstrap-icons/icons/twitter.svg);
}
.social-wrapper .social .social-icon:hover::before {
  background-color: #da9c69;
  transition: ease-in-out 0.2s;
}
@media only screen and (max-width: 1080px) {
  .social-wrapper {
    margin: 0 auto;
    position: static;
    top: 0;
    right: 0;
  }
  .social-wrapper .social {
    padding: 0;
    flex-direction: column;
  }
  .social-wrapper .social .social-icon {
    text-indent: -9999px;
    gap: 0;
  }
  .social-wrapper .social .social-icon::before {
    mask-repeat: no-repeat;
    mask-size: 50%;
    mask-position: 50% 50%;
    height: 80px;
    width: 80px;
  }
}
@media only screen and (max-width: 768px) {
  .social-wrapper .social .social-icon::before {
    height: 60px;
    width: 60px;
  }
}

.main-content.contacts {
  align-items: center;
}
.main-content.contacts .section {
  background-color: transparent;
}

.main-content.frontpage {
  align-items: center;
  flex: auto;
  flex-direction: column;
  justify-content: flex-end;
}
.main-content.frontpage .section {
  background-color: transparent;
}

.main-content {
  height: inherit;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.main-content .section {
  max-width: 100vw;
  padding: 3em 0;
  background-color: rgba(0, 0, 0, 0.4);
}
.main-content .section:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.2);
}
.main-content .section > div {
  width: 90vw;
  margin: 0 auto;
}

.band img {
  width: 60vw;
}
@media only screen and (max-width: 1080px) {
  .band img {
    width: 100%;
  }
}

.tour .section {
  background-color: transparent;
}
.tour .section .bandintown a::before {
  content: "";
  background: none;
  display: block;
  position: static;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  transition: none;
}