.tour {
  .section {
    background-color: transparent;

    .bandintown {
      a {
        &::before {
          content: '';
          background: none;
          display: block;
          position: static;
          bottom: 0;
          left: 0;
          width: 0;
          height: 0;
          transition: none;
        }
      }
    }
  }
}
